#pnf_stack {
  min-height: 100vh;
}

#pnf_h3 {
  color: #fa8ab0;
}

#pnf_h6 {
  margin: 5rem 0;
}

#pnf_img {
  height: 500px;
  width: 500px;
  padding: 2rem;
}

#pnf_btn {
  background-color: #b61588;
}

#pnf_btn:hover {
  background-color: #fa8ab0;
}
