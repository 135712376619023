#hpl_h1 {
  font-size: clamp(3rem, 8vw, 5rem);
  font-family: 'cooper black';
  color: #fa8ab0;
  -webkit-text-stroke: 2.5px #b61588;
}

#hpl_h3 {
  font-size: clamp(1.5rem, 8vw, 3.5rem);
  font-weight: bold;
}
