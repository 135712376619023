#appendix_h2 {
  font-size: 4em !important;
  font-family: 'cooper black' !important;
  color: #fa8ab0;
  -webkit-text-stroke: 2.5px #b61588;
}

.linkStyle {
  color: #fa8ab0 !important;
  text-decoration: none !important;
}

.tableIcon {
  padding: 0 !important;
}

.tableIcon {
  padding-left: 0.75rem !important;
}

.tableIcon svg {
  font-size: 1rem;
  opacity: 0.75;
}

.tableIcon svg:hover {
  transition: all 0.4s ease;
  opacity: 1;
}

#appendixBTN {
  margin-bottom: 1rem;
}

.appendixTable_row td {
  padding: 0px !important;
  padding-right: 10px !important;
}

.appendixTable_row {
  height: 40px !important;
}