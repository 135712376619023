#history_h2 {
  font-size: 4em;
  font-family: 'cooper black';
  color: #fa8ab0;
  -webkit-text-stroke: 2.5px #b61588;
}

#history_h3 {
  font-size: 2em;
  font-weight: bold;
  margin: 1em 0;
}

a {
  color: #fa8ab0;
  text-decoration: none;
}
