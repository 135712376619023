#landingPage {
  background-color: rgba(250, 138, 176, 0.75);
  height: 100dvh;
}

#landingPageInner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: white;
  width: 50dvw;
  height: 75dvh;
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 11px 15px -7px,
    rgba(0, 0, 0, 0.14) 0px 24px 38px 3px,
    rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
}

#landingPageLeft {
  display: grid;
  grid-template-rows: auto 1fr auto;
  border-right: 3px solid #b61588;
}

#landingPageRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px !important;
}

#lpl_h2 {
  font-size: clamp(1.25rem, 50vw, 2.5rem);
  font-family: "cooper black";
  color: #fa8ab0;
  text-align: center;
  padding: 1.5rem;
  -webkit-text-stroke: 2.5px #b61588;
}

#lpl_h6 {
  font-size: clamp(0.75rem, 50vw, 1.25rem);
  text-align: center;
  font-weight: bold;
  width: 95%;
  margin: auto;
  padding: 1.5rem;
}

#lpl_img {
  height: 40dvh;
}

#lpr_h5 {
  font-size: clamp(0.75rem, 50vw, 1.25rem);
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
  width: 95%;
  padding: 1.5rem;
}

#formRegisterButton,
#cancelButton {
  flex-grow: 1;
  gap: 10px;
}

#cancelButton {
  margin-left: 10px;
}

.lp_hr {
  margin-top: 10px;
  margin-bottom: 20px;
}
