#audioIcon {
  font-size: 4em;
  cursor: pointer;
}

#audioIcon:hover {
  transition: all 0.4s ease;
  color: #b61588;
  opacity: 1;
}

#audioButton {
  background-color: transparent;
}
