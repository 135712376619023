#modalBackground {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100dvw;
    height: 100dvh;
    background-color: rgba(0, 0, 0, 0.5);
}

#modalPanel {
    background-color: white;
    border: 2px solid #b61588;
    border-radius: 10px;
    padding: 1em;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
}