.galleryPicture {
  max-height: 400px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px,
    rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  margin-bottom: 30px;
}

.galleryNav {
  height: 400px;
  padding: 20px;
}

.galleryIcon {
  font-size: 40px !important;
}
